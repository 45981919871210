<template>
  <div
    ref="root" v-resize="onResizeDebounced"
    style="height: 100%; width: 100%; position: relative; min-height: 150px;"
    :class="{ 'chart--error': hasErrors }"
  >
    <div
      v-show="loading"
      class="chart__overlay"
    >
      <p>{{ $t('loading') }}</p>
    </div>
    <div
      v-show="hasErrors"
      class="chart__overlay"
    >
      <p>{{ actualError }}</p>
    </div>
    <div
      ref="chart"
      style="height: 100%; width: 100%; position: relative;"
    />
  </div>
</template>
<script>
import c3 from 'c3'
import 'c3/c3.min.css'
import { mergeDeep } from '@/utils'
import debounce from 'lodash.debounce'

const colors = [
  '#3366cc',
  '#ff9900',
  '#dc3912',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#aaaa11',
  '#6633cc',
  '#8b0707',
  '#3b3eac'
]
export default {
  props: {
    lazy: {
      type: Boolean,
      default: true
    },
    series: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator (value) {
        return ['pie', 'bar', 'column', 'timeseries', 'gauge'].indexOf(value) !== -1
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      chart: null,
      inactive: false
    }
  },
  computed: {
    actualError () {
      if (this.noData) {
        return this.$t('chart.errorNoData')
      }

      return this.error
    },
    hasErrors () {
      return !this.loading && !!this.actualError
    },
    optionsFinal () {
      if (this.type === 'timeseries') {
        return mergeDeep({}, this.optionsTimeseries, this.options)
      }

      if (this.type === 'pie') {
        return mergeDeep({}, this.optionsPie, this.options)
      }

      if (this.type === 'bar') {
        return mergeDeep({}, this.optionsBar, this.options)
      }

      if (this.type === 'column') {
        return mergeDeep({}, this.optionsColumn, this.options)
      }

      if (this.type === 'gauge') {
        return mergeDeep({}, this.optionsGauge, this.options)
      }

      return mergeDeep({}, this.options)
    },
    optionsBar () {
      return {
        data: {
          type: 'bar',
          ...this.series
        },
        tooltip: {
          grouped: false
        },
        legend: {
          show: false
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              centered: true
            }
          },
          rotated: true
        },
        color: {
          text: '#ffffff',
          pattern: this.series.colors ? this.series.colors.pattern : colors
        }
      }
    },
    optionsColumn () {
      return {
        data: {
          type: 'bar',
          ...this.series
        },
        tooltip: {
          grouped: false
        },
        legend: {
          show: false
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              centered: true
            }
          }
        },
        color: {
          text: '#ffffff',
          pattern: this.series.colors ? this.series.colors.pattern : colors
        },
        grid: {
          y: {
            show: true
          }
        }
      }
    },
    optionsPie () {
      return {
        data: {
          type: 'pie',
          ...this.series
        },
        color: {
          text: '#ffffff',
          pattern: this.series.colors ? this.series.colors.pattern : colors
        }
      }
    },
    optionsTimeseries () {
      return {
        data: this.series,
        connectNull: true,
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%d/%m/%Y'
            }
          }
        },
        color: {
          text: '#ffffff',
          pattern: this.series.colors ? this.series.colors.pattern : colors
        },
        grid: {
          y: {
            show: true
          }
        }
      }
    },
    optionsGauge () {
      return {
        data: {
          type: 'gauge',
          ...this.series
        },
        color: {
          text: '#ffffff',
          pattern: this.series.colors ? this.series.colors.pattern : colors
        }
      }
    },
    noData () {
      return !this.optionsFinal.data || !this.optionsFinal.data.columns || !this.optionsFinal.data.columns.length
    },
    onResizeDebounced () {
      return debounce(this.onResize, 100)
    }
  },
  watch: {
    optionsFinal: 'generate'
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', this.trackPageHidden)
    this.destroyChart()
  },
  mounted () {
    document.addEventListener('visibilitychange', this.trackPageHidden, false)
    if (!this.lazy) {
      return
    }

    this.generate()
  },
  methods: {
    trackPageHidden () {
      this.inactive = !!document.hidden
    },
    destroyChart () {
      if (!this.chart) {
        return
      }

      this.chart.destroy()
      this.chart = null
    },
    onResize ({ width, height }) {
      if (!this.chart || !this.chart.internal || !this.chart.internal.api) {
        return
      }

      this.chart.internal.api.resize({
        width,
        height
      })
    },
    generate () {
      if (!this.optionsFinal || !this.optionsFinal.data || !this.optionsFinal.data.columns) {
        return
      }

      if (this.inactive) {
        return
      }

      if (this.chart) {
        const load = (this.optionsFinal && this.optionsFinal.data) || {}
        if (this.type === 'timeseries') {
          load.unload = true
        }

        this.chart.load(load)
        return
      }

      const el = this.$refs.chart
      if (!el) {
        return
      }

      const root = this.$refs.root
      const width = root ? root.clientWidth : el.clientWidth
      const height = root ? root.clientHeight : el.clientHeight

      this.chart = c3.generate({
        bindto: el,
        size: {
          width,
          height
        },
        ...this.optionsFinal
      })
    }
  }
}
</script>
<style lang="scss">
$x: #1f2128;
$x-light: lighten($x, 5%);
$y: #dddddf;
$y-dark: darken($y, 5%);

.chart__overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 98;
  border-radius: 4px;

  background-color: #fff;
  color: #2a2d36;

  .dashboard.is-dark & {
    background-color: #2a2d36;
    color: #fff;
  }
}

.c3 {
  text {
    fill: currentColor;
  }

  .c3-chart-arcs {
    .c3-chart-arcs-background {
      fill: $y;
      .dashboard.is-dark & {
        fill: $x;
        stroke: lighten($x, 30%);
      }
    }
  }

  .c3-chart-arc {
    text {
      .dashboard.is-dark & {
        fill: #fff;
      }
    }
  }

  line, path {
    stroke: currentColor;

    &.c3-arc {
      stroke: #fff;
    }
  }

  .dashboard.is-dark & {
    color: $y;
  }
}

.c3-grid {
  line {
    opacity: 0.5;
    .dashboard.is-dark & {
      stroke: lighten($x, 30%);
    }
  }
}

.c3-circle {
  stroke-width: 2px;
  stroke: #fff;
  .dashboard.is-dark & {
    stroke: $x-light;
  }
}

.c3-line {
  stroke-width: 2px;
}

.c3-tooltip {
  .dashboard.is-dark & {
    background-color: $x-light;
    box-shadow: none;
    tr {
      border-color: lighten($x, 15%);
    }

    td {
      background-color: $x;
    }

    th {
      background-color: lighten($x, 15%);
    }
  }
}
</style>
